.App {
  text-align: center; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.x.axis path {
  display: none; }

.x.axis line {
  stroke: rgba(100, 100, 100, 0.8);
  stroke-dasharray: 5; }
