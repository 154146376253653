.App-header {
  height: 15vh;
  color: white; }

div.header {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

div.info {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

div.header img {
  width: 30px;
  transform: rotate(180deg); }

div.header h2 {
  margin: 0px;
  align-self: center; }

h4 {
  padding: 0px;
  margin: 0px; }

div.data {
  height: 100%;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.wave-data-bro-noaa {
  display: flex;
  padding-bottom: 10px; }

.wave, .wind {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; }

.wave .name {
  padding-right: 5px; }

.wave.hsbro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #00afe6;
  padding: 6px 0 0 12px; }

.wave.hsnoaa {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #9a9a9a;
  padding: 6px 0 0 12px; }

.dir span.sec {
  display: inline-block;
  width: 22px; }

@media (max-width: 420px) {
  .dir span {
    width: 14px; } }

.wave.dir {
  color: white; }
  .wave.dir .wave-sec, .wave.dir h4 {
    color: red; }

.svg {
  position: relative;
  width: 50px;
  height: 50px; }

span.dir {
  position: absolute;
  top: 33%;
  right: 39%;
  font-size: 8px;
  font-weight: 900;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }

span.dir-wind {
  position: absolute;
  top: 45%;
  right: 41%;
  font-size: 8px;
  font-weight: 900;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }

.wave img {
  width: 17px;
  position: absolute;
  bottom: 34%;
  left: 33.7%; }

.wind svg circle {
  stroke: white; }

.wind img {
  width: 11px;
  position: absolute;
  bottom: 56%;
  left: 39%; }

.wave-size {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 75px; }

.wave-logo {
  display: block;
  text-indent: -9999px;
  font-size: 0;
  width: 33px;
  height: 33px;
  background-image: url(https://broou.com/static/img/widget-sprites-sa0d4d457b2.png);
  background-position: -2015px 0;
  background-size: 2556px 71px;
  margin: 0 auto; }

circle.bigdir {
  stroke: white;
  stroke-width: 4px;
  fill: transparent; }

circle.dir {
  stroke: white;
  stroke-width: 3px;
  fill: white; }
