body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
  position: fixed; }

@media (max-width: 420px) {
  body {
    font-size: 14px; } }

#root {
  background-color: #1f1e1e; }

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges; }

.x.axis text {
  font-weight: 100;
  stroke: #1f1e1e;
  fill: #1f1e1e;
  font-size: 13px;
  opacity: 0.8; }

.y.axis path {
  stroke: transparent; }

.y.axis line {
  stroke: white;
  stroke-width: 1; }

.y.axis text {
  font-weight: 400;
  fill: white; }

.line {
  stroke: #00afe6;
  stroke-width: 0px; }

.line-noaa {
  fill: #9a9a9a;
  stroke: #9a9a9a;
  stroke-width: 3px; }

.line-min, .line-max {
  stroke: white;
  stroke-width: 3px; }

.area {
  fill: lightsteelblue; }
